.change-pass{
  background-color: #ffffff;
  height: 100%;
}
.change-pass__logo img{
  margin: auto;
  width: 150px;
}
.forgot h3{
  font-size:1.2rem;
}
.change-pass__spin{
  display: flex;
  justify-content: center;
}
